'use client';

import Counter, { CounterProps } from '@components/Counter/Counter';
import { TestingElement } from '@components/types.ts';
import classNames from 'classnames/bind';
import { snakeCase } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';

import styles from './CounterGroup.module.scss';

const cx = classNames.bind(styles);

export type CounterGroupProps = {
  countersProps: Array<CounterProps & { name: string }>
  fullWidth?: boolean,
  inline?: boolean,
  className?: string,
  setValues: Dispatch<SetStateAction<Record<string, number>>>;
  values: Record<string, number>;
} & TestingElement;

function CounterGroup(props: CounterGroupProps) {
  const {
    countersProps,
    fullWidth = false,
    inline = false,
    className,
    setValues,
    values,
    testId,
  } = props;

  const handleCounterChange = (index: number) => (value: number) => {
    if (setValues) {
      setValues((prev) => (
        {
          ...prev,
          [snakeCase(countersProps[index].name)]: value,
        }
      ));
    }
  };

  return (
    <div
      data-testid={testId}
      className={cx('counter__group', className, {
        'counter__group--inline': inline,
        'counter__group--full-width': !inline && fullWidth,
      })}
    >
      {countersProps.map((value, index) => (
        <Counter
          {...value}
          key={value.name}
          defaultValue={values[snakeCase(value.name)]}
          value={values[snakeCase(value.name)]}
          onChange={handleCounterChange(index)}
          fullWidth={!inline ? fullWidth : false}
        />
      ))}
    </div>
  );
}

export default CounterGroup;
