'use client';

import AccordionItem, { AccordionItemProps } from '@components/Accordion/AccordionItem';
import { TestingElement } from '@components/types.ts';
import classNames from 'classnames/bind';
import React, { useCallback } from 'react';

import styles from './Accordion.module.scss';

const cx = classNames.bind(styles);

export type AccordionProps = TestingElement & {
  className?: string;
  items: Array<Omit<AccordionItemProps, 'isOpen'>>
};

export function Accordion(props: AccordionProps) {
  const {
    className,
    items,
    testId,
  } = props;

  const [itemsState, setItemsState] = React.useState(
    Array.from({ length: items.length }, () => ({ isOpen: false })),
  );

  const onOpenAccordionItem = useCallback((index: number) => {
    const newItemsState = Array.from({ length: items.length }, () => ({ isOpen: false }));
    const openAccordion = itemsState[index].isOpen;
    if (openAccordion) {
      newItemsState[index].isOpen = false;
    } else {
      newItemsState[index].isOpen = !newItemsState[index].isOpen;
    }
    setItemsState(newItemsState);
  }, [items, itemsState]);

  const accordionClassName = cx('accordion', className);

  return (
    <div className={accordionClassName} data-testid={testId}>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          {...item}
          isOpen={itemsState[index].isOpen}
          onOpenAccordion={() => onOpenAccordionItem(index)}
        />
      ))}
    </div>
  );
}

export default Accordion;
