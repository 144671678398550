'use client';

import { Icon } from '@components/Icon';
import { IconName } from '@components/Icon/Icon';
import { Typography } from '@components/Typography';
import classNames from 'classnames/bind';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import {
  ToastContainer as ToastifyContainer,
  toast,
  ToastContainerProps as ToastifyContainerProps,
  TypeOptions as ToastifyTypeOptions,
} from 'react-toastify';

import styles from './Toast.scss';

const cx = classNames.bind(styles);

export enum ToastVariant {
  Default = 'default',
  Brand = 'brand',
  Success = 'success',
  Danger = 'danger',
}

export type ToastContainerProps = Omit<ToastifyContainerProps, 'theme'>;

export default function ToastContainer(
  props: ToastContainerProps,
) {
  return <ToastifyContainer {...props} theme="colored" />;
}

export function addToast({
  variant = 'default',
  title,
  message,
  icon,
}: {
  variant?: `${ToastVariant}`
  title?: string,
  message?: string,
  icon?: `${IconName}` | false,
}) {
  const variantMapping: Record<ToastVariant, { icon: `${IconName}`, type: ToastifyTypeOptions }> = {
    [ToastVariant.Default]: {
      icon: IconName.InformationCircle,
      type: 'default',
    },
    [ToastVariant.Brand]: {
      icon: IconName.InformationCircle,
      type: 'warning',
    },
    [ToastVariant.Success]: {
      icon: IconName.CheckmarkCircle02,
      type: 'success',
    },
    [ToastVariant.Danger]: {
      icon: IconName.Alert02,
      type: 'error',
    },
  };

  const content = (
    <div className={cx('toast', {
      [`toast--${variant}`]: variant,
    })}
    >
      {icon !== false && (
        <Icon name={icon ?? variantMapping[variant].icon} />
      )}
      <div>
        {title && <Typography fontStyle="bold">{title}</Typography>}
        {message && <Typography size="small">{message}</Typography>}
      </div>
    </div>
  );

  const closeButton = ({ closeToast }) => (
    <button
      type="button"
      className={cx('toast__close-button', {
        [`toast__close-button--${variant}`]: variant,
      })}
      aria-label="Fermer"
      onClick={closeToast}
    >
      <Icon size="sm" name="Close" />
    </button>
  );

  return toast(content, {
    type: variantMapping[variant].type, icon: false, closeButton,
  });
}
