'use client';

import Icon, { IconName } from '@components/Icon/Icon';
import { TestingElement } from '@components/types.ts';
import classNames from 'classnames/bind';
import React, { ReactNode, useId } from 'react';
import { Button } from 'react-aria-components';

import styles from './AccordionItem.module.scss';

const cx = classNames.bind(styles);

export type AccordionItemProps = TestingElement & {
  title?: string;
  iconName?: `${IconName}`;
  content: ReactNode,
  onOpenAccordion?: () => void;
  isOpen: boolean;
};

export function Accordion(props: AccordionItemProps) {
  const {
    title,
    iconName,
    content,
    isOpen,
    onOpenAccordion,
    testId,
  } = props;

  const id = useId();

  const accordionItemClassName = cx('accordion-item', {
    'accordion-item--open': isOpen,
  });

  return (
    <div className={accordionItemClassName} data-testid={testId}>
      <Button
        className={styles['accordion-item__header']}
        aria-expanded={isOpen}
        aria-controls={id}
        onPress={onOpenAccordion}
      >
        <Icon
          className={`${styles['accordion-item__header__icon']} ${iconName ? '' : styles['expand-icon']}`}
          name={iconName ?? 'ArrowDown01'}
          data-expand-icon={iconName ? 'false' : 'true'}
        />
        <p className={styles['accordion-item__header__title']}>
          {title}
        </p>
        {iconName && (
          <Icon
            className={`${styles['accordion-item__header__icon']} ${styles['expand-icon']}`}
            name="ArrowDown01"
            data-expand-icon="true"
          />
        )}
      </Button>

      <div
        id={id}
        className={styles['accordion-item__content']}
      >
        {content}
      </div>
    </div>
  );
}

export default Accordion;
